<template>
  <div
    :class="[
      `info-2`,
      `--${size}`,
      `--${theme}`,
      { '--loading': loading },
    ]"
    :style="{
      background,
      padding,
      borderRadius,
    }"
  >
    <SasIcon
      v-if="iconName"
      v-show="!loading"
      :class="`info__icon  --${size}`"
      :size="icon.size"
      :stroke="icon.color || $tokens.color_ink_lighter"
      :stroke-width="icon.strokeWidth"
      :type="iconName"
    />

    <transition
      mode="out-in"
      name="fade"
    >
      <div
        v-if="loading"
        class="info__content --loading"
      >
        <SkeletonLoader
          height="14px"
          width="60%"
        />
        <SkeletonLoader
          :height="`${skeletonBoneHeight}px`"
          width="80%"
        />
      </div>

      <div
        v-else
        class="info__content"
      >
        <component
          :is="labelTag"
          v-if="label"
          class="info__label"
          v-html="label"
        />
        <component
          :is="textTag"
          v-if="text"
          class="info__text"
          :style="{ color }"
          v-html="text"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Info',

  props: {
    loading: Boolean,

    icon: {
      type: [Object, String],
      default: () => ({
        name: '',
        color: '',
      }),
    },

    label: {
      type: String,
      default: '',
    },

    text: {
      type: [String, Number],
      default: '',
    },

    background: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      default: '',
    },

    borderRadius: {
      type: String,
      default: '',
    },

    padding: {
      type: String,
      default: '4px',
    },

    size: {
      type: String,
      default: 'small',
    },

    theme: {
      type: String,
      default: 'light',
    },
  },

  computed: {
    textTag() {
      switch (this.size) {
      case 'x-large':
        return 'h1'
      case 'large':
        return 'h3'
      case 'medium':
        return 'h4'
      case 'small':
        return 'p'
      case 'x-small':
        return 'span'
      default:
        return 'p'
      }
    },

    skeletonBoneHeight() {
      const skeletonHeight = {
        'X-LARGE': 48,
        LARGE: 28,
        MEDIUM: 20,
        SMALL: 16,
        'X-SMALL': 13,
      }

      if (!this.size) {
        return skeletonHeight.SMALL
      }

      return skeletonHeight[this.size.toUpperCase]
    },

    labelTag() {
      return this.size === 'x-large' ? 'h5' : 'label'
    },

    iconName() {
      return typeof this.icon === 'string' ? this.icon : this.icon.name
    },

  },
}
</script>

<style lang="sass">

.info-2
  display: flex
  align-items: center
  box-sizing: border-box

  &.--loading
    width: 100%

  &.--light
    .info__label
      color: $color-ink-light
  &.--dark
    .info__label
      color: rgba(255, 255, 255, .75)

.info

  &__content.--loading
    width: 100%

  // Icon
  &__icon
    flex-shrink: 0

    // Sizes
    &.--x-small
      margin-right: 8px
      svg
        width: 20px
        height: 20px

    &.--small
      margin-right: 8px
      svg
        width: 24px
        height: 24px

    &.--medium
      margin-right: 8px
      svg
        width: 32px
        height: 32px

    &.--large
      margin-right: 12px
      svg
        width: 48px
        height: 48px

    &.--x-large
      margin-right: 16px
      +mq-l
        margin-right: 12px
      +mq-m
        margin-right: 8px

      svg
        width: 64px
        height: 64px

        +mq-l
          width: 48px
          height: 48px
        +mq-m
          width: 40px
          height: 40px

  &__label
    font-weight: 500
    margin-bottom: 5px
    margin-left: $size-xs

  h1, h2, h3, h4, h5, h6,
  span, p, label,
  &__text,
  &__label
    line-height: 1
    margin-left: $size-xs

</style>
