var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    "info-2",
    ("--" + _vm.size),
    ("--" + _vm.theme),
    { '--loading': _vm.loading } ],style:({
    background: _vm.background,
    padding: _vm.padding,
    borderRadius: _vm.borderRadius,
  })},[(_vm.iconName)?_c('SasIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],class:("info__icon  --" + _vm.size),attrs:{"size":_vm.icon.size,"stroke":_vm.icon.color || _vm.$tokens.color_ink_lighter,"stroke-width":_vm.icon.strokeWidth,"type":_vm.iconName}}):_vm._e(),_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[(_vm.loading)?_c('div',{staticClass:"info__content --loading"},[_c('SkeletonLoader',{attrs:{"height":"14px","width":"60%"}}),_c('SkeletonLoader',{attrs:{"height":(_vm.skeletonBoneHeight + "px"),"width":"80%"}})],1):_c('div',{staticClass:"info__content"},[(_vm.label)?_c(_vm.labelTag,{tag:"component",staticClass:"info__label",domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),(_vm.text)?_c(_vm.textTag,{tag:"component",staticClass:"info__text",style:({ color: _vm.color }),domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }