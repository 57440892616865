var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"areas-performance"},[_c('h3',{staticClass:"areas-performance__title"},[_vm._v(" Áreas do Conhecimento ")]),(!_vm.mq_m)?_c('DataTable',{attrs:{"data":_vm.areasPerformance,"density":_vm.tableDensity,"dont-sort-on-init":"","error":_vm.hasError,"fields":_vm.areasFields,"loading":_vm.loading},on:{"refresh":function($event){return _vm.reload()}},scopedSlots:_vm._u([{key:"score",fn:function(ref){
var row = ref.row;
return _c('b',{},[_vm._v(" "+_vm._s(_vm.formatScore(row.score))+" ")])}},{key:"averageScore",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatScore(row.averageScore))+" ")]}},{key:"highestScore",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatScore(row.highestScore))+" ")]}},{key:"lowestScore",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatScore(row.lowestScore))+" ")]}}],null,false,1606845968)}):_c('transition-group',{staticClass:"areas-performance__grid",attrs:{"appear":"","name":"cards","tag":"section"}},_vm._l((_vm.areasPerformance),function(area){return _c('InfoCard',{key:area.name,staticClass:"areas-performance__card",attrs:{"title":area.name}},[_c('Info',{attrs:{"label":"Sua Nota","size":"large","text":_vm.formatScore(area.score)}}),_c('Info',{staticClass:"areas-performance__card__info",attrs:{"label":"Média Nacional","size":"medium","text":_vm.formatScore(area.averageScore)}}),_c('div',{staticClass:"result-subjects__area-card__footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',[_vm._v("Menor TRI: "+_vm._s(_vm.formatScore(area.lowestScore)))]),_c('span',[_vm._v("Maior TRI: "+_vm._s(_vm.formatScore(area.highestScore)))])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }