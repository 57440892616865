<template>
  <section class="areas-performance">
    <h3 class="areas-performance__title">
      Áreas do Conhecimento
    </h3>

    <DataTable
      v-if="!mq_m"
      :data="areasPerformance"
      :density="tableDensity"
      dont-sort-on-init
      :error="hasError"
      :fields="areasFields"
      :loading="loading"
      @refresh="reload()"
    >
      <b
        slot="score"
        slot-scope="{ row }"
      >
        {{ formatScore(row.score) }}
      </b>

      <template
        slot="averageScore"
        slot-scope="{ row }"
      >
        {{ formatScore(row.averageScore) }}
      </template>

      <template
        slot="highestScore"
        slot-scope="{ row }"
      >
        {{ formatScore(row.highestScore) }}
      </template>

      <template
        slot="lowestScore"
        slot-scope="{ row }"
      >
        {{ formatScore(row.lowestScore) }}
      </template>
    </DataTable>

    <transition-group
      v-else
      appear
      class="areas-performance__grid"
      name="cards"
      tag="section"
    >
      <InfoCard
        v-for="area in areasPerformance"
        :key="area.name"
        class="areas-performance__card"
        :title="area.name"
      >
        <Info
          label="Sua Nota"
          size="large"
          :text="formatScore(area.score)"
        />

        <Info
          class="areas-performance__card__info"
          label="Média Nacional"
          size="medium"
          :text="formatScore(area.averageScore)"
        />

        <div
          slot="footer"
          class="result-subjects__area-card__footer"
        >
          <span>Menor TRI: {{ formatScore(area.lowestScore) }}</span>
          <span>Maior TRI: {{ formatScore(area.highestScore) }}</span>
        </div>
      </InfoCard>
    </transition-group>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DataTable from '@/components/DataTable'
import InfoCard from '@/components/InfoCard'
import Info from '@/components/Info'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'RankedKnowledgeAreasPerformance',

  components: {
    DataTable,
    InfoCard,
    Info,
  },

  mixins: [mediaQueries],

  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      areasScoreResult: {
        scores: [],
        globalScores: [],
      },
      loading: false,
      hasError: false,
      areasFields: [
        {
          text: 'Área do Conhecimento',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Sua nota',
          value: 'score',
          sortable: true,
        },
        {
          text: 'Média Nacional',
          value: 'averageScore',
          sortable: true,
        },
        {
          text: 'Maior Nota Nacional',
          value: 'highestScore',
          sortable: true,
        },
        {
          text: 'Menor Nota Nacional',
          value: 'lowestScore',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    ...mapGetters([
      'assessment',
      'isDigitalEnem',
    ]),

    tableDensity() {
      if (this.mq_m) {
        return 'medium'
      } if (this.mq_s) {
        return 'small'
      }

      return 'large'
    },

    schoolAreasPerformance() {
      return this.areasScoreResult.scores
    },

    globalAreasPerformance() {
      return this.areasScoreResult.globalScores
    },

    areasPerformance() {
      return this.globalAreasPerformance.map((performance) => {
        // eslint-disable-next-line no-unused-vars
        const { name, score } = this.schoolAreasPerformance.find(
          // eslint-disable-next-line no-shadow
          ({ name }) => name === performance.name,
        )

        return {
          ...performance,
          score,
          averageScore: performance.score,
        }
      })
    },
  },

  created() {
    this.loadAreasPerformance()
  },

  methods: {
    ...mapActions([
      'getAreasScoreResult',
      'getScoreResultByAssessment',
    ]),

    reload() {
      this.loadAreasPerformance(true)
    },

    async loadAreasPerformance(force = false) {
      this.hasError = false
      this.loading = true
      let params = null
      let action = null

      try {
        if (this.isDigitalEnem) {
          params = {
            assessmentId: this.assessment.id,
            force,
          }
          action = 'getScoreResultByAssessment'
        } else {
          const { examId } = this

          params = {
            examId,
            force,
          }
          action = 'getAreasScoreResult'
        }

        const result = await this[action](params)

        this.areasScoreResult = result
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },

    formatScore(score) {
      return score.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
  },
}
</script>

<style lang="sass">

.areas-performance

  &__title
    margin-bottom: 24px
    padding: 0

    +mq-m
      padding: 0 16px

  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 16px
    padding: 0 16px
    box-sizing: border-box

    +mq-s
      grid-template-columns: 100%
    +mq(425px)
      padding: 0

  &__card
    +mq-s
      border-radius: 0 !important

    .info-card__title
      color: $color-ink
      font-size: 18px

    .info-2:not(:first-child)
      margin-left: 32px
      align-items: flex-start

    .info-card__footer span
      color: $color-ink-light

      &:not(:first-child)
        margin-left: 24px
        +mq-s
</style>
